<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Crear día de facturación</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="6" class="mt-5">
              <v-select
              :rules="billingRules"
                v-model="day"
                :items="days"
                prepend-icon="mdi-view-list"
                label="Seleccionar día *"
              ></v-select>
              <v-textarea
                v-model="description"
                :rules="descriptionRules"
                :counter="190"
                prepend-icon="mdi-format-list-group"
                label="Descripción"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="3"></v-col>
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="4"></v-col>
            <v-col cols="12" lg="8">
              <div class="text-right mr-15">
                <v-btn color="primary" outlined class="mr-4" @click="cancel">
                  <v-icon>mdi-close-circle</v-icon> Cancelar
                </v-btn>
                <v-btn color="primary" @click="create" outlined>
                  <v-icon>mdi-check-circle</v-icon> Crear
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    description: "",
    menu1: false,
    day: "",
    days: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28,
    ],
    billingRules: [
      v => !!v || 'El día es requerido'
    ],
    descriptionRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
  }),

  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "billing-periods" }), 10);
    },

    create() {
      console.log("entra");

      let request = {
        billing_period: this.day,
        description: this.description,
      };
      axios
        .post("/save-billing-period", request)
        .then((response) => {
          this.displayNotification("success", "Éxito","Día de facturación creado correctamente");
          console.log(response.data);
          setTimeout(
            () => this.$router.push({ name: "billing-periods" }),
            4000
          );
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al crear el día de facturación"
          );
          setTimeout(
            () => this.$router.push({ name: "billing-periods" }),
            4000
          );
        });
    },
  },
};
</script>